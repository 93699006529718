/* .card */

.card {
  background-color: #fff;
  border-radius: 12px;
  padding: 33px 25px 28px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  color: #11151E;

  &__wrap {
    display: flex;
  }

  .product-index & {
    @media@md {
      margin-bottom: 15px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  &__view {
    padding-top: 3px;
  }

  &__view img {
    width: 80px;
    min-width: 80px;
  }

  &__label {
    flex-grow: 1;
  }

  &__title {
    font-size: 22px;
    margin-top: 0;
    color: #111D33;
    margin-bottom: 0;
  }

  &__subtitle {
    text-transform: uppercase;
    color: #8C97AC;
    font-size: 12px;
    font-family: @ff_base-500;
  }

  &__body {
    font-size: 15px;
    margin-bottom: 23px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
  }

  &__footer .btn {
    margin-bottom: 5px;
  }

  &__price {
    font-size: 43px;
    font-weight: 700;
    line-height: 1;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}