/* .benefits */

@media@md {/* .benefits @md */}

.benefits {
  &__item-wrap {
    margin-bottom: 8px;

    @media@md {
      display: flex;
    }
  }
  .benefits--sidebar &__item-wrap {
    @media@md {
      margin-bottom: 16px;
    }
  }
  &__item {
    background-color: #fff;
    border-radius: 12px;
    padding: 8px 10px 8px 8px;
    display: flex;
    
    @media@md {
      flex-direction: column;
      padding: 32px 22px;
    }
  }
  &__item:last-child {
    margin-bottom: 0;
  }
  .benefits--sidebar &__item {
    @media@md {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      padding: 32px 24px;
    }
  }
  &__view {
    width: 93px;
    min-width: 93px;
    margin-right: 17px;
    
    @media@md {
      width: 100%;
      margin-right: 0;
      text-align: center;
      margin-bottom: 17px;
    }
  }
  .benefits--sidebar &__view {
    @media@md {
      width: auto;
      text-align: left;
      margin-bottom: 0;
      margin-right: 15px;
    }
  }
  &__label {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: @ff_second-bold;
    line-height: 1.3;
  }
}