/* .btn */

.btn {
  display: inline-block;
  font-family: @ff_third;
  font-weight: 400;
  font-size: 15px;
  background-color: #EB2655;
  text-decoration: none;
  color: #F3F3F3;
  padding: 7px 15px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #EB2655;

  &:hover {
    background-color: lighten(#EB2655, 10%);
    border-color: lighten(#EB2655, 10%);
  }

  &--long {
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  &--lg {
    font-size: 18px;
    padding: 13px 20px;
  }

  &--transparent {
    background-color: transparent;
    color: #1D293F;
    border: 1px solid #BAC7DF;
    font-weight: 500;
  }
  
  &--transparent:hover {
    background-color: #fff;
    border: 1px solid #BAC7DF;
  }

  &--submit {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    padding: 7px 19px;
    border: 1px solid #D1D8E3;
    background-color: transparent;
    color: #1D293F;
  }

  &--submit:hover {
    color: #F3F3F3;
  }

  // .shipping-total
  .shipping-total & {
    font-size: 18px;
    font-weight: 500;
    padding: 13px 20px;
    font-family: 'Roboto', sans-serif;
  }
}