/* .tbl */

@media@md {/* .tbl @md */}

.tbl {
  font-size: 15px;
  color: #37445E;

  @media@md {
    font-size: 18px;
  }

  &__tbody-td-inner {
    display: flex;
  }

  &__thead-th {
    font-family: @ff_base;
    font-size: 12px;
    color: #8C97AC;
  }

  &__tbody-td {
    background-color: #fff;
  }

  .tbl--product &__el,
  .tbl--product &__thead,
  .tbl--product &__thead-tr,
  .tbl--product &__tbody,
  .tbl--product &__tbody-td {
    display: block;
  }

  .tbl--product &__el {
    @media@md {
      width: 100%;
      display: table;
    }
  }

  .tbl--product &__thead {
    @media@md {
      display: table-header-group;
    }
  }

  .tbl--product &__thead-tr {
    @media@md {
      display: table-row;
    }
  }
  
  .tbl--product &__thead-th {
    display: none;
    padding: 10px 18px;

    @media@md {
      display: table-cell;
      text-align: right;
      padding: 10px;
    }
  }
  
  .tbl--product &__thead-th--package-prod {
    display: block;
    text-align: left;

    @media@md {
      text-align: left;
      padding-left: 16px;
    }
  }

  .tbl--product &__thead-th--order-prod {
    @media@md {
      text-align: center;
    }
  }
  
  .tbl--product &__tbody {
    @media@md {
      display: table-row-group;
    }
  }
  
  .tbl--product &__tbody-tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    margin-bottom: 1px;
    padding: 11px 22px 10px 17px;

    @media@md {
      display: table-row;
    }
  }

  .tbl--product &__tbody-tr:first-child {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  .tbl--product &__tbody-tr:first-child td:first-child {
    @media@md {
      border-radius: 8px 0 0 0;
    }
  }

  .tbl--product &__tbody-tr:first-child td:last-child {
    @media@md {
      border-radius: 0 8px 0 0;
    }
  }

  .tbl--product &__tbody-tr:last-child {
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  .tbl--product &__tbody-tr:last-child td {
    @media@md {
      border-bottom: none;
    }
  }

  .tbl--product &__tbody-tr:last-child td:first-child {
    @media@md {
      border-radius: 0 0 0 8px;
    }
  }

  .tbl--product &__tbody-tr:last-child td:last-child {
    @media@md {
      border-radius: 0 0 8px 0;
    }
  }

  .tbl--product &__tbody-td {
    @media@md {
      display: table-cell;
      border-bottom: 1px solid #EFF0F2;
      padding: 10px;
    }
  }

  .tbl--product &__tbody-td .tbl__tbody-td-inner {
    justify-content: flex-end;
  }

  .tbl--product &__tbody-td--package-prod {
    @media@md {
      font-size: 15px;
      padding-left: 16px;
    }
  }

  .tbl--product &__tbody-td--package-prod .tbl__tbody-td-inner {
    flex-direction: column;

    @media@md {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .tbl--product &__tbody-td--savings-prod {
    @media@md {
      font-family: @ff_base-500;
      color: #EB2655;
    }
  }

  .tbl--product &__tbody-td--order-prod .tbl__tbody-td-inner {
    @media@md {
      justify-content: center;
    }
  }

  .tbl--product &__tbody-td--order-prod .btn span {
    display: none;

    @media@md {
      display: inline;
    }
  }

  .tbl--product &__tbody-td--order-prod .btn {
    margin-top: -5px;

    @media@md {
      margin-top: 0;
    }
  }

  .tbl--product &__tbody-td--price-prod span {
    display: block;
    font-size: 15px;
    font-family: @ff_base;
    color: #8C97AC;
    padding-top: 4px;

    @media@md {
      display: none;
    }
  }

  .tbl--product &__tbody-td--savings-prod,
  .tbl--product &__tbody-td--per-pill-prod {
    display: none;
  }

  .tbl--product &__tbody-td--price-prod {
    font-size: 18px;
    font-family: @ff_base-500;
    margin-left: auto;
    margin-right: 21px;
  }
  
  .tbl--product &__tbody-td--price-prod .tbl__tbody-td-inner {
    flex-direction: column;
    align-items: flex-end;
  }

  // .tbl--shopping-cart
  &.tbl--shopping-cart {
    padding-top: 14px;
    font-size: 18px;
    margin-bottom: 39px;

    @media@md {
      padding-top: 9px;
      margin-bottom: 25px;
    }

    table,
    thead,
    tbody,
    tr,
    th,
    td {
      display: block;
    }

    @media@md {
      table {
        display: table;
      }
      thead {
        display: table-header-group;
      }
      tbody {
        display: table-row-group;
      }
      tr {
        display: table-row;
      }
      th,
      td {
        display: table-cell;
      }
    }

    .tbl {
      &__thead-th {
        margin-bottom: 4px;

        @media@md {
          margin-bottom: 0;
          padding-bottom: 9px;
        }
      }
      
      &__thead-th {
        display: none;

        @media@md {
          display: table-cell;
          padding-left: 13px;
          padding-right: 13px;
        }
      }
      
      &__thead-th--product {
        display: block;
        padding-left: 17px;
        text-align: left;
      }
      
      &__tbody {
        margin-bottom: 1px;
      }

      &__tbody:nth-child(2) {
        border-radius: 8px 8px 0 0;
        overflow: hidden;
      }

      &__tbody:nth-child(2) tr:first-child td:first-child {
        @media@md {
          border-radius: 8px 0 0 0;
        }
      }

      &__tbody:nth-child(2) tr:first-child td:last-child {
        @media@md {
          border-radius: 0 8px 0 0;
        }
      }

      &__tbody:last-child {
        border-radius: 0 0 8px 8px;
        overflow: hidden;
      }

      &__tbody:last-child tr:last-child td:first-child {
        border-radius: 0 0 0 8px;
      }

      &__tbody:last-child tr:last-child td:last-child {
        border-radius: 0 0 8px 0;
      }

      &__tbody-tr {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-left: 12px;
        padding-right: 9px;
        padding-bottom: 8px;
        background-color: #fff;
        
        @media@md {
          display: table-row;
        }
      }

      &__tbody-tr:last-child .tbl__tbody-td {
        @media@md {
          border-bottom: 1px solid #EDEEF0;
        }
      }

      &__tbody-td-inner {
        @media@md {
          align-items: center;
        }
      }

      &__tbody-td {
        @media@md {
          padding: 13px;
          vertical-align: middle;
        }
      }

      &__tbody-td--product {
        padding-left: 72px;
        width: 100%;
        font-family: @ff_base-500;
        padding-top: 13px;
        margin-bottom: 2px;
        order: 1;

        @media@md {
          width: auto;
          padding-left: 16px;
        }
      }

      &__tbody-td--product img {
        position: absolute;
        top: 14px;
        left: 9px;
        width: 68px;

        @media@md {
          position: static;
          width: 63px;
          margin-right: 11px;
        }
      }

      &__tbody-td--package {
        width: 100%;
        padding-left: 72px;
        order: 2;
        margin-bottom: 11px;

        @media@md {
          width: auto;
        }
      }

      &__tbody-td--qty {
        order: 4;
        margin-right: auto;
      }

      &__tbody-td--price {
        display: none;
        white-space: nowrap;
        
        @media@md {
          display: table-cell;
        }
      }
      
      &__tbody-td--subtotal {
        order: 5;
        font-family: @ff_base-500;
        margin-top: -3px;
        white-space: nowrap;
      }
      
      &__tbody-td--remove {
        order: 3;
        margin-right: 20px;
      }
      
      &__tbody-td--remove a {
        text-decoration: none;
        color: #8C97AC;
        font-size: 15px;
      }
      
      &__tbody-td--remove a:hover {
        text-decoration: underline;
      }
      
      &__tbody-td--upgrade {
        width: 100%;
        font-family: 'Roboto', sans-serif;
        padding-top: 7px;
        padding-left: 4px;
        margin-bottom: -3px;
      }
      
      &__tbody-td--upgrade a {
        text-decoration: none;
      }
      
      &__tbody-td--upgrade a:hover {
        text-decoration: underline;
      }

      &__tbody-td--price .tbl__tbody-td-inner,
      &__tbody-td--subtotal .tbl__tbody-td-inner,
      &__tbody-td--remove .tbl__tbody-td-inner {
        @media@md {
          justify-content: flex-end;
        }
      }

      &__tbody-td--upgrade .tbl__tbody-td-inner {
        display: block;
        font-size: 11px;
        text-align: center;

        @media@md {
          text-align: left;
          margin-top: -8px;
          padding-left: 11px;
          margin-bottom: -7px;
        }
      }
    }
  }

  // .tbl--shopping-method
  &.tbl--method {

    @media@md {
      margin-bottom: 50px;
    }

    .tbl {
      &__thead-th {
        padding-bottom: 9px;
      }

      &__thead-th--method {
        text-align: left;
        padding-left: 18px;
      }

      &__thead-th--price {
        text-align: right;
        padding-right: 18px;
      }
      
      &__tbody-tr:first-child .tbl__tbody-td {
        border-bottom: 1px solid #EDEEF0;
      }

      &__tbody-tr:first-child .tbl__tbody-td:first-child {
        border-radius: 8px 0 0 0;
      }

      &__tbody-tr:first-child .tbl__tbody-td:last-child {
        border-radius: 0 8px 0 0;
      }

      &__tbody-tr:last-child .tbl__tbody-td:first-child {
        border-radius: 0 0 0 8px;
      }

      &__tbody-tr:last-child .tbl__tbody-td:last-child {
        border-radius: 0 0 8px 0;
      }

      &__tbody-td {
        font-size: 18px;
        font-family: @ff_base-500;
        padding-top: 17px;
        padding-bottom: 15px;
      }

      &__tbody-td--method {
        padding-left: 16px;
      }

      &__tbody-td--price {
        padding-right: 17px;
        white-space: nowrap;
      }

      &__tbody-td--price .tbl__tbody-td-inner {
        // @media@md {
          justify-content: flex-end;
        // }
      }
    }
  }
}