/* .accordion */

@media@md {/* .accordion @md */}

.accordion {
  
  @media@md {
    max-width: 784px;
    margin: 0 auto;
  }

  &__item {
    border: 1px solid #D1D8E3;
    border-radius: 4px;
    margin-bottom: 12px;
    font-size: 18px;
  }
  &__item:last-child {
    margin-bottom: 0;
  }
  &__control {
    display: none;
  }
  &__label {
    display: block;
    font-family: @ff_base-500;
    padding: 15px 40px 15px 15px;
    cursor: pointer;
    position: relative;
  }
  &__label::before {
    position: absolute;
    top: 50%;
    right: 22px;
    margin-top: -9px;

    .accordion__control:checked ~ & {
      transform: rotate(90deg);
    }
  }
  &__body {
    display: none;
    padding: 2px 15px 15px;

    .accordion__control:checked ~ & {
      display: block;
    }
  }
}