/* .tabs */

@media@md {/* .tabs @md */}
@media@xl {/* .tabs @xl */}

.tabs {
  display: block;
  padding-top: 34px;

  @media@md {
    margin-bottom: 30px;
  }

  &--product {
    margin-bottom: -12px;
  }

  &__nav--product {
    display: flex;
    overflow-x: auto;
  }

  &__radio {
    display: none;
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    color: #5c6471;
    font-weight: bold;
    padding: 6px 17px;
    font-family: @ff_base-bold;

    white-space: nowrap;

    .tabs__radio:checked + & {
      color: #202225;
      border-bottom: 4px solid #2265da;
    }
  }

  &__item {
    display: none;
    padding-top: 16px;
  }

  .product-page &__item {
    padding-top: 2px;
  }

  // .payment-info .tabs
  .payment-info & {
    padding-top: 1px;
    margin-bottom: 36px;

    &__item {
      padding-top: 12px;

      @media@md {
        padding-top: 9px;
      }
    }

    &__item-credit-card {
      @media@md {
        background: url("../img/card.png") no-repeat;
        background-size: contain;
        margin-left: -18px;
        padding-left: 40px;
        padding-top: 9%;
        padding-right: 34%;
        position: relative;
        min-height: 380px;
      }

      @media@xl {
        // background: url("../img/card.png") no-repeat;
        // background-size: contain;
        // margin-left: -18px;
        padding-left: 68px;
        padding-top: 86px;
        padding-right: 242px;
        // position: relative;
        // min-height: 380px;
      }
    }
  }
}
