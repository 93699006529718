// ***************************************
// Mixins
// ***************************************
.fontFace(@font) {
	@font-face {
	    font-family: '@{font}';
	    src: url('../fonts/@{font}/@{font}.eot');
	    src: url('../fonts/@{font}/@{font}.eot?#iefix') format('embedded-opentype'),
	         url('../fonts/@{font}/@{font}.woff2') format('woff2'),
	         url('../fonts/@{font}/@{font}.woff') format('woff'),
	         url('../fonts/@{font}/@{font}.ttf') format('truetype'),
	         url('../fonts/@{font}/@{font}.svg#sansus_webissimoregular') format('svg');
	    font-weight: normal;
	    font-style: normal;
	}
}

// Подключение шрифтов
// .fontFace('letosans');
.fontFace('helvetica-neue');
.fontFace('helvetica-neue-500');
.fontFace('raleway');
.fontFace('raleway-bold');