/* .form */

@media @md {/* .form @md */}
@media @xl {/* .form @xl */}

.form {
  &--cart {
    margin-bottom: 0;
  }
  &__row {
    display: flex;
    margin-bottom: 8px;

    .tabs__item-credit-card & {
      @media@md {
        margin-bottom: 24px;
      }
    }

    .tabs__item-credit-card &:last-child {
      @media @md {
        position: absolute;
        top: 38%;
        right: 2%;
        display: flex;
        flex-direction: column;
        width: 136px;
        margin-bottom: 0;
      }
      @media @xl {
        top: 166px;
        right: 39px;
      }
    }
  }
  &__field {
    flex-grow: 1;
    margin-right: 15px;
    overflow: hidden;

    .coupon & {
      @media@md {
        max-width: 215px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
  &__field--sm {
    flex-grow: 0;
    width: 100%;
    min-width: 136px;
    max-width: 136px;
  }
  &__field--lbl {
    position: relative;
  }
  &__field--lbl.icon:before {
    position: absolute;
    pointer-events: none;
  }
  &__field--lbl.icon-question:before {
    top: 20px;
    right: 12px;
    cursor: pointer;
    pointer-events: all;
  }
  &__field--lbl.icon-mastercard:before {
    top: 12px;
    right: 12px;
  }
  &__field--ccv {
    margin-right: 9px;

    @media@md {
      margin-bottom: 16px;
    }
  }
  &__field--select {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 26px;
      right: 16px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: #031333 transparent transparent transparent;
      pointer-events: none;
    }
  }
  &__lbl-input {
    position: absolute;
    top: 14px;
    left: 16px;
    font-size: 18px;
    pointer-events: none;
    transition: all ease 0.3s;
    color: #bac0c9;


    top: 5px;
    line-height: 1.2;
    align-items: center;
    height: 46px;
    display: flex;
  }
  &__input,
  &__textarea {
    width: 100%;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-radius: 4px;
    border: 1px solid #D1D8E3;
    outline: none;

    .form__field--lbl & {
      transition: all ease 0.3s;
      color: transparent;
    }

    .form__field--lbl &:focus,
    .form__field--lbl.opened & {
      padding-top: 20px;
      color: @c_text;

      & + .form__lbl-input {
        color: #5c6471;
        position: absolute;
        left: 16px;
        font-size: 11px;
        pointer-events: none;
        align-items: flex-start;
      }
    }

    .form__field--lbl &:focus {
      border: 3px solid #2265DA;
      box-shadow: 0px 2px 12px rgba(172, 202, 248, 0.5);
    }

    .form__field.error & {
      border-color: @c_brand;
    }
  }
  &__textarea {
    height: 192px !important;
    resize: none;
  }
  &__input {
    height: 56px !important;
  }
  &__select {
    width: 100%;
    height: 56px;
    appearance: none;
    padding-left: 15px;
    padding-right: 30px;
    border-radius: 4px;
    color: #bac0c9;
    border: 1px solid #D1D8E3;
    outline: none;
  }
  &__select:focus {
    border: 3px solid #2265DA;
    box-shadow: 0px 2px 12px rgba(172, 202, 248, 0.5);
    padding-left: 13px;
  }
  &__option {
    color: @c_text;
  }
  &__desc {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-right: 60px;

    @media @md {
      padding-right: 10px;
    }
  }

  &__error {
    font-size: 13px;
    line-height: 16px;
    color: @c_brand;
    display: none;
    padding-top: 3px;

    .form__field.error & {
      display: block;
    }
  }

  &__submit {
    background-color: #2265DA;
    border-radius: 4px;
    color: #F3F3F3;
    border: none;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 16px;
    cursor: pointer;
    max-width: 400px;
  }

  &__submit:hover {
    background-color: lighten(#2265DA, 10%);
  }

  // .form--contact
  &--contact {
    margin-top: -60px;
    margin-bottom: 48px;
    padding-top: 38px;
    padding-bottom: 21px;

    @media@xl {
      margin-bottom: 96px;
    }

    p {
      font-size: 15px;
      color: #37445E;
      margin-bottom: 26px;
    }

    .form {
      &__row {
        margin-bottom: 12px;
        flex-wrap: wrap;
      }
      &__textarea {
        margin-bottom: -8px;
      }
    }
  }
}
