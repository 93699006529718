/* .radio */

.radio {
    display: flex;
    align-items: center;

    &__input {
        appearance: none;
        border: none;
    }

    &__label {
        font-size: 18px;
        // color: #444;
        padding-left: 31px;
        padding-right: 15px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        font-family: @ff_base;

        &:before {
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            border: 1px solid #D2D4D9;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -9px;
            z-index: 1;
        }

        &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 6px;
            margin-top: -3px;
            z-index: 2;
            opacity: 0;
        }

        .radio__input:checked + &:before {
            border-color: #2265DA;
            background-color: #2265DA;
        }

        .radio__input:checked + &::after {
            opacity: 1;
        }
    }
}