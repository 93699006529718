/* .shipping-total */

@media@sm {/* .shipping-total @sm */}

.shipping-total {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
  padding-bottom: 14px;
  border-bottom: 1px solid #EFF0F2;

  @media@sm {
    border-bottom: none;
  }

  &__sum {
    font-size: 43px;
    font-family: @ff_base-bold;
    font-weight: 700;

    @media@sm {
      margin-right: 11px;
    }
  }

  &__label {
    @media@sm {
      margin-right: 11px;
      position: relative;
      top: 9px;
    }
  }
}