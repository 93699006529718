/* .reviews */

@media@md {/* .reviews @md */}

.reviews {
  text-align: center;
  padding-top: 52px;
  padding-bottom: 44px;

  @media@md {
    padding-top: 76px;
    padding-bottom: 67px;
    margin-bottom: 0;
  }

  &__title {
    margin-top: 1px;
    margin-bottom: 27px;
  }
  &__text {
    padding: 0 1px;
    margin-bottom: 12px;

    @media@md {
      max-width: 580px;
      margin: 0 auto;
      margin-bottom: 14px;
    }
  }
  &__footer {
    font-family: @ff_second-bold;
    font-size: 22px;
  }
}