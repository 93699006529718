/* .banner */

@media@md {/* .banner @md */}

.banner {
  padding-top: 48px;
  padding-bottom: 48px;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  @media@md {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 63px;
    padding-left: 100px;
    padding-right: 12px;
    padding-bottom: 5px;
  }

  &__view {
    margin-bottom: 17px;
    text-align: center;

    @media@md {
      padding-top: 52px;
      margin-bottom: 0;
    }
  }

  &__view img {
    width: 326px;

    @media@md {
      min-width: 466px;
    }
  }

  &__body {
    @media@md {
      margin-right: 12px;
      margin-bottom: 20px;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.4px;
    margin-top: 0;
    margin-bottom: 16px;
    
    @media@md {
      font-size: 52px;
      line-height: 56px;
      text-align: left;
    }
  }

  &__title span {
    display: block;
  }

  &__desc {
    margin-bottom: 27px;
  }

  &__btn {
    text-align: center;
    
    @media@md {
      text-align: left;
    }
  }

  &__btn .btn {
    @media@md {
      max-width: none;
      width: auto;
      padding: 13px 19px;
    }
  }
}