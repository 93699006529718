/* .navigation */

@media@md {/* .navigation @md */}

.navigation {
  background-color: #fff;
  font-family: @ff_second-bold;
  font-size: 15px;
  padding-bottom: 72px;
  margin-bottom: 32px;

  @media@md {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__list {
    margin: 0;
    padding: 0;

    @media@md {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    display: block;
    margin-bottom: 0;
  }

  &__item::before {
    display: none;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 72px;
    text-decoration: none;
    color: #111D33;

    @media@md {
      padding: 0 16px;
      height: auto;
    }
  }
  &__link.active {
    border-left: 4px solid #2265DA;
    padding-left: 20px;

    @media@md {
      border-left: none;
      padding-left: 16px;
    }
  }
  &__link:hover {
    background-color: #F5F6F8;

    @media@md {
      background-color: transparent;
    }
  }
}