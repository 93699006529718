/* .checkbox */

.checkbox {
  &__input {
    appearance: none;
    position: absolute;
    border: none;
  }

  &__label {
    position: relative;
    font-size: 18 px;
    color: #444;
    padding-left: 29px;
    display: inline-block;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      border: 1px solid #ccc;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      top: 3px;
      left: 0;
      z-index: 1;
    }

    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      // background-image: url();
      background-color: @c_brand;
      opacity: 0;
      border-radius: 2px;
      position: absolute;
      top: 3px;
      left: 0;
      z-index: 2;
    }

    .checkbox__input:checked + &:before {
      display: inline-block;
      vertical-align: middle;
      background-image: url("../img/@{spritesheet-image}") !important;
      .sprite(@icon-check);
      z-index: 5;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 8px;
      left: 5px;
    }

    .checkbox__input:checked + &:after {
      opacity: 1;
    }
  }
}
