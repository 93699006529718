/* .crumbs */

@media@md{/* .crumbs @md */}

.crumbs {
  font-size: 12px;
  font-family: @ff_base-500;
  padding: 15px 0;

  @media@md {
    padding: 20px 0;
  }

  &__item {
    color: #BDC4D1;
    text-decoration: none;
    margin-right: 8px;
  }
  &__item:hover {
    color: #f8f8f8;
  }
  &__item::after {
    content: "/";
    padding-left: 8px;
  }
  &__item:hover::after {
    color: #BDC4D1;
  }
  &__item:last-child:after {
    display: none;
  }
  &__item--active {
    color: #F8F8F8;
  }
}