/* .header */

@media@md {/* .header @md */}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  position: fixed;
  min-height: 72px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;

  @media@md {
    justify-content: flex-start;
    position: static;
    padding: 0;
  }

  &__wrap-navigation {
    position: fixed;
    top: 71px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F5F6F8;
    transform: translateX(-100%);
    overflow-y: auto;
    transition: transform ease-in-out .3s;

    @media@md {
      position: static;
      display: flex;
      flex-grow: 1;
      background-color: transparent;
      transform: translateX(0);
      align-items: center;
      overflow: hidden;
    }
  }

  &__wrap-navigation.opened {
    transform: translateX(0);
  }

  &__logo {
    position: relative;
    top: 4px;
    left: -3px;

    @media@md {
      top: 0;
      left: 0;
      margin-right: 14px;
    }
  }

  &__logo img {
    width: 124px;
    height: auto;

    @media@md {
      min-width: 164px;
      position: relative;
      top: 3px;
    }
  }

  &__toggle-menu {
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-left: 10px;
    outline: none;
    width: 32px;

    @media@md {
      display: none;
    }
  }

  &__top-controller {
    @media@md {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }

  &__cart {
    @media@md {
      padding-top: 4px;
    }
  }
}