/* typography */

@media@md {
  /* typography @md */
}

* {
  box-sizing: border-box;
}

body {
  background-color: @c_bgBody;
  // background-color: #f00;
  font-family: @ff_base;
  font-size: @fz;
  line-height: @lh;
  color: @c_text;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  font-weight: 700;
  font-family: @ff_second-bold;
  letter-spacing: -0.5px;
  line-height: 1.2;
}

h1 {
  font-size: @fz_h1;
}

h2 {
  font-size: @fz_h2;
  margin-bottom: 18px;

  @media@md {
    font-size: 52px;
    line-height: 56px;
    text-align: center;
    margin-bottom: 31px;
  }

  span {
    display: block;

    @media@md {
      display: inline;
      margin-right: 12px;
    }
  }
}

h3 {
  font-size: 32px;
  letter-spacing: 0.2px;
  line-height: 1.6;
  margin-bottom: 21px;
}

h4 {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
}

a {
  color: #2265da;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

p {
  margin: 0 0 0.8em 0;
}

.product-page p {
  margin-bottom: 24px;
  color: #37445e;
}

img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

ul {
  margin: 0 0 28px;
  padding: 0 0 0 28px;
  list-style: none;
  color: #37445e;
}

ul li {
  margin-bottom: 12px;
  position: relative;
}

ul li::before {
  content: "";
  position: absolute;
  top: 9px;
  left: -18px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2265da;
}

ul li:last-child {
  margin-bottom: 0;
}

.product-page ul {
  margin-top: -8px;
}

table {
}

// svg {
//     display: inline-block;
//     vertical-align: middle;
// }

// input,
// input[type=text],
// input[type=email],
// input[type=number],
// input[type=tel],
// input[type=search],
// input[type=password],
// input[type=time],
// input[type=date],
// input[type=datetime],
// input[type=datetime-local],
// input[type=color],
// select,
// textarea {
//     color: #000;
//     border: 1px solid @c_gray_light;
//     padding: 5px 10px;
//     background-color: #fff;
//     outline: none;

//     &::-webkit-input-placeholder {color:@c_gray_light;}
//     &::-moz-placeholder          {color:@c_gray_light;}/* Firefox 19+ */
//     &:-moz-placeholder           {color:@c_gray_light;}/* Firefox 18- */
//     &:-ms-input-placeholder      {color:@c_gray_light;}
// }

// input,
// input[type=text],
// input[type=email],
// input[type=number],
// input[type=tel],
// input[type=search],
// input[type=password],
// input[type=time],
// input[type=date],
// input[type=datetime],
// input[type=datetime-local],
// input[type=color],
// select {
//     height: 32px;
// }

// button,
// input[type=submit] {
//     background-color: #fff;
//     border: 1px solid @c_gray_light;
// }

// legend {
//     font-size: 0.875em;
//     color: @c_text;
// }

// fieldset {
//     border: 1px solid @c_gray_light;
// }

table {
  width: 100%;
  border-collapse: collapse;
}

// td,
// th {
//     border: none;
//     padding: 5px;
// }
