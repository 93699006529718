/* .footer */

@media@md {/* .footer @md */}

.footer {
  font-size: 12px;
  color: #BDC4D1;
  font-family: inherit;
  padding-top: 31px;
  padding-bottom: 35px;
  line-height: 1;

  p {
    line-height: 1.4;
    margin-bottom: 12px;
  }

  &__text {
    margin-bottom: 35px;
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #384865;
    padding-bottom: 20px;

    @media@md {
      justify-content: flex-start;
      padding-bottom: 22px;
    }
  }

  &__logo {
    @media@md {
      margin-right: 31px;
      margin-top: -4px;
    }
  }

  &__logo img {
    width: 125px;
    
    @media@md {
      width: auto;
    }
  }

  &__phone {
    font-size: 15px;

    img {
      position: relative;
      top: 0;
    }
  }

  &__phone-desc {
    display: block;
    font-size: 12px;
    color: #E4E7EE;
    margin-bottom: 10px;
  }

  &__social-notes {
    width: 100%;
    padding-top: 19px;

    @media@md {
      width: auto;
      padding-top: 8px;
      margin-left: auto;
    }
  }

  &__bottom {
    padding-top: 28px;

    @media@md {
      display: flex;
      justify-content: space-between;
    }
  }

  &__copyright {
    line-height: 1.4;
    margin-bottom: 22px;
  }

  &__nav {
    @media@md {
      margin-top: 2px;
    }
  }

  &__nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__nav-item {
    display: inline-block;
    margin-right: 14px;
    margin-bottom: 12px;
  }

  &__nav-item::before {
    display: none;
  }

  &__nav-item:last-child {
    margin-right: 0;
  }

  &__nav-link {
    text-decoration: none;
    color: #E4E7EE;
  }

  &__nav-link:hover {
    text-decoration: underline;
  }
}