/* .payment-info */

.payment-info {

  @media@md {
    margin-top: -12px;
  }

  h2 {
    letter-spacing: 0;

    @media@md {
      margin-bottom: 15px;
    }
  }
}