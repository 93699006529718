/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@icon-accordion-name: 'icon-accordion';
@icon-accordion-x: 90px;
@icon-accordion-y: 0px;
@icon-accordion-offset-x: -90px;
@icon-accordion-offset-y: 0px;
@icon-accordion-width: 11px;
@icon-accordion-height: 18px;
@icon-accordion-total-width: 101px;
@icon-accordion-total-height: 84px;
@icon-accordion-image: 'sprite.png';
@icon-accordion: 90px 0px -90px 0px 11px 18px 101px 84px 'sprite.png' 'icon-accordion';
@icon-cart-name: 'icon-cart';
@icon-cart-x: 68px;
@icon-cart-y: 0px;
@icon-cart-offset-x: -68px;
@icon-cart-offset-y: 0px;
@icon-cart-width: 21px;
@icon-cart-height: 21px;
@icon-cart-total-width: 101px;
@icon-cart-total-height: 84px;
@icon-cart-image: 'sprite.png';
@icon-cart: 68px 0px -68px 0px 21px 21px 101px 84px 'sprite.png' 'icon-cart';
@icon-check-name: 'icon-check';
@icon-check-x: 90px;
@icon-check-y: 19px;
@icon-check-offset-x: -90px;
@icon-check-offset-y: -19px;
@icon-check-width: 11px;
@icon-check-height: 9px;
@icon-check-total-width: 101px;
@icon-check-total-height: 84px;
@icon-check-image: 'sprite.png';
@icon-check: 90px 19px -90px -19px 11px 9px 101px 84px 'sprite.png' 'icon-check';
@icon-close-menu-name: 'icon-close-menu';
@icon-close-menu-x: 68px;
@icon-close-menu-y: 37px;
@icon-close-menu-offset-x: -68px;
@icon-close-menu-offset-y: -37px;
@icon-close-menu-width: 16px;
@icon-close-menu-height: 16px;
@icon-close-menu-total-width: 101px;
@icon-close-menu-total-height: 84px;
@icon-close-menu-image: 'sprite.png';
@icon-close-menu: 68px 37px -68px -37px 16px 16px 101px 84px 'sprite.png' 'icon-close-menu';
@icon-flag-es-name: 'icon-flag-es';
@icon-flag-es-x: 43px;
@icon-flag-es-y: 25px;
@icon-flag-es-offset-x: -43px;
@icon-flag-es-offset-y: -25px;
@icon-flag-es-width: 24px;
@icon-flag-es-height: 16px;
@icon-flag-es-total-width: 101px;
@icon-flag-es-total-height: 84px;
@icon-flag-es-image: 'sprite.png';
@icon-flag-es: 43px 25px -43px -25px 24px 16px 101px 84px 'sprite.png' 'icon-flag-es';
@icon-flag-us-name: 'icon-flag-us';
@icon-flag-us-x: 0px;
@icon-flag-us-y: 68px;
@icon-flag-us-offset-x: 0px;
@icon-flag-us-offset-y: -68px;
@icon-flag-us-width: 24px;
@icon-flag-us-height: 16px;
@icon-flag-us-total-width: 101px;
@icon-flag-us-total-height: 84px;
@icon-flag-us-image: 'sprite.png';
@icon-flag-us: 0px 68px 0px -68px 24px 16px 101px 84px 'sprite.png' 'icon-flag-us';
@icon-prod-bonus-name: 'icon-prod-bonus';
@icon-prod-bonus-x: 43px;
@icon-prod-bonus-y: 0px;
@icon-prod-bonus-offset-x: -43px;
@icon-prod-bonus-offset-y: 0px;
@icon-prod-bonus-width: 24px;
@icon-prod-bonus-height: 24px;
@icon-prod-bonus-total-width: 101px;
@icon-prod-bonus-total-height: 84px;
@icon-prod-bonus-image: 'sprite.png';
@icon-prod-bonus: 43px 0px -43px 0px 24px 24px 101px 84px 'sprite.png' 'icon-prod-bonus';
@icon-prod-hot-name: 'icon-prod-hot';
@icon-prod-hot-x: 0px;
@icon-prod-hot-y: 43px;
@icon-prod-hot-offset-x: 0px;
@icon-prod-hot-offset-y: -43px;
@icon-prod-hot-width: 24px;
@icon-prod-hot-height: 24px;
@icon-prod-hot-total-width: 101px;
@icon-prod-hot-total-height: 84px;
@icon-prod-hot-image: 'sprite.png';
@icon-prod-hot: 0px 43px 0px -43px 24px 24px 101px 84px 'sprite.png' 'icon-prod-hot';
@icon-prop-free-ship-name: 'icon-prop-free-ship';
@icon-prop-free-ship-x: 25px;
@icon-prop-free-ship-y: 43px;
@icon-prop-free-ship-offset-x: -25px;
@icon-prop-free-ship-offset-y: -43px;
@icon-prop-free-ship-width: 24px;
@icon-prop-free-ship-height: 24px;
@icon-prop-free-ship-total-width: 101px;
@icon-prop-free-ship-total-height: 84px;
@icon-prop-free-ship-image: 'sprite.png';
@icon-prop-free-ship: 25px 43px -25px -43px 24px 24px 101px 84px 'sprite.png' 'icon-prop-free-ship';
@icon-qty-minus-name: 'icon-qty-minus';
@icon-qty-minus-x: 68px;
@icon-qty-minus-y: 54px;
@icon-qty-minus-offset-x: -68px;
@icon-qty-minus-offset-y: -54px;
@icon-qty-minus-width: 16px;
@icon-qty-minus-height: 2px;
@icon-qty-minus-total-width: 101px;
@icon-qty-minus-total-height: 84px;
@icon-qty-minus-image: 'sprite.png';
@icon-qty-minus: 68px 54px -68px -54px 16px 2px 101px 84px 'sprite.png' 'icon-qty-minus';
@icon-qty-plus-name: 'icon-qty-plus';
@icon-qty-plus-x: 50px;
@icon-qty-plus-y: 43px;
@icon-qty-plus-offset-x: -50px;
@icon-qty-plus-offset-y: -43px;
@icon-qty-plus-width: 16px;
@icon-qty-plus-height: 16px;
@icon-qty-plus-total-width: 101px;
@icon-qty-plus-total-height: 84px;
@icon-qty-plus-image: 'sprite.png';
@icon-qty-plus: 50px 43px -50px -43px 16px 16px 101px 84px 'sprite.png' 'icon-qty-plus';
@icon-reviews-name: 'icon-reviews';
@icon-reviews-x: 0px;
@icon-reviews-y: 0px;
@icon-reviews-offset-x: 0px;
@icon-reviews-offset-y: 0px;
@icon-reviews-width: 42px;
@icon-reviews-height: 42px;
@icon-reviews-total-width: 101px;
@icon-reviews-total-height: 84px;
@icon-reviews-image: 'sprite.png';
@icon-reviews: 0px 0px 0px 0px 42px 42px 101px 84px 'sprite.png' 'icon-reviews';
@icon-sandwich-name: 'icon-sandwich';
@icon-sandwich-x: 68px;
@icon-sandwich-y: 22px;
@icon-sandwich-offset-x: -68px;
@icon-sandwich-offset-y: -22px;
@icon-sandwich-width: 20px;
@icon-sandwich-height: 14px;
@icon-sandwich-total-width: 101px;
@icon-sandwich-total-height: 84px;
@icon-sandwich-image: 'sprite.png';
@icon-sandwich: 68px 22px -68px -22px 20px 14px 101px 84px 'sprite.png' 'icon-sandwich';
@spritesheet-width: 101px;
@spritesheet-height: 84px;
@spritesheet-image: 'sprite.png';
@spritesheet-sprites: @icon-accordion @icon-cart @icon-check @icon-close-menu @icon-flag-es @icon-flag-us @icon-prod-bonus @icon-prod-hot @icon-prop-free-ship @icon-qty-minus @icon-qty-plus @icon-reviews @icon-sandwich;
@spritesheet: 101px 84px 'sprite.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  background-image: e(%('url(%a)', e(@sprite-image)));
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
