/* .top-controller */

@media@md {/* .top-controller @md */}

.top-controller {
  padding: 0 15px;
  margin-bottom: 32px;

  @media@md {
    margin-bottom: 0;
    padding: 0;
    margin-right: 43px;
    position: relative;
    top: 2px;
  }

  &__item {
    display: none;
    width: 100%;
    align-items: center;
    padding: 6px 0;
    text-decoration: none;
    font-size: 15px;
    color: #37445E;
  }
  
  &__item:before {
    margin-right: 8px;
    margin-top: -2px;
  }

  &__item.active {
    display: flex;
  }

  &__item:hover span {
    text-decoration: underline;
  }
}