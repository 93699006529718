/* .prod-info */

@media@md {/* .prod-info @md */}

.prod-info {
  // background-color: #fff;
  // border-radius: 12px;
  // padding: 32px 26px;
  // box-shadow: 0px 100px 100px rgba(19, 33, 53, 0.04), 0px 22.3363px 22.3363px rgba(19, 33, 53, 0.0238443), 0px 6.6501px 6.6501px rgba(19, 33, 53, 0.0161557);
  margin-bottom: -20px;

  @media@md {
    margin-bottom: 15px;
  }

  &__view {
    display: flex;
    align-items: flex-end;
    margin-bottom: 33px;
  }

  &__view img + img {
    padding-left: 8px;
    width: 76px;
  }

  &__row {
    margin-bottom: 27px;
  }

  &__row:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-size: 22px;
    line-height: 28px;
    font-family: @ff_second-bold;
    margin-top: 0;
    margin-bottom: 9px;
  }

  &__text {
    font-size: 15px;
    line-height: 24px;
  }

  &__text a,
  &__text span {
    white-space: nowrap;
    display: inline-block;
    margin-right: 4px;
  }
  
  &__text a::after,
  &__text span::after {
    content: "/";
    margin-left: 4px;
    color: #8C97AC;
    text-decoration: none;
    display: inline-block;
  }
  &__text a:last-child:after,
  &__text span:last-child:after {
    display: none;
  }
}