/* .cart */

@media@md {/* .cart @md */}

.cart {
  margin-right: 14px;
  text-decoration: none;

  @media@md {
    display: flex;
    align-items: center;
    margin-right: 0;
  }

  &__sum,
  &__link {
    display: none;

    @media@md {
      display: block;
      white-space: nowrap;
      font-family: @ff_base-500;
      font-size: 15px;
    }
  }

  &__link {
    text-decoration: underline;
  }

  &__link:hover {
    text-decoration: none;
  }

  &__sum {
    color: #111D33;

    @media@md {
      margin-right: 12px;
    }
  }

  &__icon::before {
    @media@md {
      position: relative;
      display: block;
      margin-right: 10px;
      top: -2px;
    }
  }
}