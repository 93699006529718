/* Layout */

@media@sm {
  /* Layout @sm */
}
@media@md {
  /* Layout @md */
}

html,
body {
  height: 100%;
}

body {
  background-color: #f3f4f9;
}

// .maket("../maket/products-mob.png", 2978px);
// @media@md {
//   .maket("../maket/products-desktop.png", 2499px);
// }

.container {
  width: 100%;
  max-width: 1214px;
  margin: 0 auto;
  padding: 0 15px;

  // max-width: 360px;
  // @media@xl {
  //   max-width: 1214px;
  // }
}

.row {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}

.col-12,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3 {
  padding-left: 8px;
  padding-right: 8px;
}

.col-12 {
  width: 100%;
}

.col-sm-6 {
  @media@sm {
    width: 50%;
  }
}

.col-md-8 {
  @media@md {
    width: 66.666%;
  }
}

.col-md-7 {
  @media@md {
    width: 58.333%;
  }
}

.col-md-6 {
  @media@md {
    width: 50%;
  }
}

.col-md-5 {
  @media@md {
    width: 41.666%;
  }
}

.col-md-4 {
  @media@md {
    width: 33.333%;
  }
}

.col-md-3 {
  @media@md {
    width: 25%;
  }
}

.view-to-md {
  @media@md {
    display: none !important;
  }
}

.view-from-sm {
  display: none !important;

  @media@sm {
    display: block !important;
  }
}

.view-from-md {
  display: none !important;

  @media@md {
    display: block !important;
  }
}

.page {
  &__wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 72px;

    @media@md {
      padding-top: 0;
      background-color: #fff;
    }
  }
  &__banner {
    background-color: #2265da;
  }
  &__crumbs {
    background-color: #2265da;
    margin-bottom: 24px;

    @media@md {
      margin-bottom: 38px;
    }
  }
  &__main {
    padding-top: 0;
    width: 100%;
    margin-bottom: 95px;

    &.products-page {
      margin-bottom: 0;
    }
  }
  &__main--index {
    padding-top: 53px;

    @media@md {
      padding-top: 101px;
    }
  }
  &__main.product-page {
    @media@md {
      padding-right: 23px;
    }
  }
  &__title {
    font-size: @fz_h1;
    margin-top: 0;
    margin-bottom: 15px;

    @media@md {
      font-size: 52px;
      line-height: 56px;
      margin-bottom: 17px;
    }
  }
  &__title--index {
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 17px;
  }
  &__center {
    margin-bottom: auto;
  }
  &__section {
    margin-bottom: 48px;

    @media@md {
      margin-bottom: 40px;
    }
  }
  &__section--blue {
    background-color: #2265da;
    color: #eff0f2;

    .products-page & {
      margin-bottom: 0;
    }

    @media@md {
      margin-bottom: 97px;
    }
  }
  &__sidebar {
    margin-top: -47px;
    margin-bottom: 39px;

    @media@md {
      margin-top: -65px;
    }
  }
  &__footer {
    background-color: #1d293f;
    color: #bdc4d1;
  }
}

.account-page,
.coupon-page,
.subscribe-page {

  input,
  textarea {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    border: 1px solid #D1D8E3;
    outline: none;
  }

  input {
    height: 56px;
  }

  textarea {
    height: 192px !important;
  }

  input[type="submit"] {
    padding: 6px 30px !important;
    border-radius: 4px !important;
  }

  input[type="submit"]:hover {
    background-color: #4c83e3 !important;
    color: #fff !important;
  }

  input[name="vcode"] {
    margin-right: 5px;
  }

  img.vcode {
    margin-right: 5px;
  }

  table,
  thead,
  tbody,
  tfoot,
  tr,
  td {
    display: block;
  }

  table {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @media @sm {
      display: table;
    }
  }
  thead {
    @media @sm {
      display: table-header-group;
    }
  }
  tbody {
    @media @sm {
      display: table-row-group;
    }
  }
  tfoot {
    @media @sm {
      display: table-footer-group;
    }
  }
  tr {
    @media @sm {
      display: table-row;
    }
  }
  th,
  td {
    @media @sm {
      display: table-cell;
    }
  }
  table input {
    width: 100%;

    @media @sm {
      width: auto;
    }
  }
  table input[name="vcode"] {
    width: auto;
  }
  table button,
  table input[type="submit"] {
    display: inline-block;
    background-color: @c_brand;
    border: 1px solid @c_brand;
    color: #fafafa;
    padding: 6px 11px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-family: @ff_base;
    width: auto;

    &:hover {
      background-color: @c_bgBody;
      color: @c_text;
    }
  }
  th,
  td {
    padding: 0;

    @media @sm {
      padding: 5px;
      text-align: left;
    }
  }
  th:first-child,
  td:first-child {
    @media @sm {
      text-align: right;
    }
  }
  tr {
    margin-bottom: 4px;
  }
  tbody {
    order: 0;
  }
  tfoot {
    order: 1;
  }
  tfoot th:first-child {
    display: none;

    @media @sm {
      display: table-cell;
    }
  }
  tfoot th[colspan="2"] {
    display: block;
    text-align: left;

    @media @sm {
      padding-left: 31%;
    }
  }
}

table.simple {
  margin: 15px 0;

  thead th,
  thead td {
    font-family: @ff_second;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    color: #5c6471;
    font-weight: bold;

    &:first-child {
      text-align: left;
    }
  }
  tbody td,
  tbody th {
    border-top: 1px solid #d2d4d9;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
}

.faq-page h2,
.policies-page h2,
.sitemap-page h2,
.bonuses-page h2 {
  text-align: left;
  font-size: 32px;
  margin-bottom: 15px;
}

.faq-page h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.testimonial {
  border-top: 1px solid #d2d4d9;
  padding-top: 15px;
  margin-bottom: 15px;

  .test-header {
    text-align: right;
    font-weight: bold;
    color: @c_brand;
    margin-bottom: 10px;
  }

  .test-text {
    font-style: italic;
    font-size: 14px;
  }
}

.block-bordered {
  background-color: #fff;
  border-radius: 12px;
  padding: 32px 26px;
  box-shadow: 0px 100px 100px rgba(19, 33, 53, 0.04),
    0px 22.3363px 22.3363px rgba(19, 33, 53, 0.0238443),
    0px 6.6501px 6.6501px rgba(19, 33, 53, 0.0161557);
}

.fz28 {
  font-size: 28px !important;
}
.fz32-md {
  @media@md {
    font-size: 32px !important;
  }
}
.t-left {
  text-align: left !important;
}
.t-right {
  text-align: right !important;
}
.t-center {
  text-align: center !important;
}
.t-left-md {
  @media@md {
    text-align: left !important;
  }
}
.t-right-md {
  @media@md {
    text-align: right !important;
  }
}
.t-center-md {
  @media@md {
    text-align: center !important;
  }
}
.mb8-md {
  @media@md {
    margin-bottom: 8px;
  }
}
