/* .shipping-footer */

@media@sm {/* .shipping-footer @sm */}
@media@md {/* .shipping-footer @md */}

.shipping-footer {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 17px 20px 25px;
  margin-bottom: -46px;

  @media@md {
    padding-top: 15px;
    margin-bottom: -1px;
  }

  &__top {
    margin-bottom: 21px;

    @media@sm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EFF0F2;
    }
  }

  &__bottom {
    @media@md {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__sertify {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    @media@md {
      margin-top: -4px;
    }
  }

  &__sertify img:first-child {
    @media@md {
      margin-right: 38px;
    }
  }

  &__sertify-desc {
    font-size: 11px;
    line-height: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-bottom: 15px;

    @media@md {
      margin-bottom: 0;
      max-width: 450px;
      margin-right: 14px;
    }
  }
}
