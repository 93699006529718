/* .qty */

.qty {
  display: flex;
  align-items: center;

  @media@md {
    margin: 0 auto;
  }

  &__btn {
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
    outline: none;
    margin-top: -2px;
    font-size: 18px;
    opacity: .2;
    transition: opacity ease-in-out .3s;
  }

  &__btn:hover {
    opacity: 1;
  }

  &__input {
    text-align: center;
    padding: 0 !important;
    width: 56px;
    border: none !important;
    background-color: transparent !important;
    outline: none;
    font-family: @ff_base;
  }
}
