/* billing-address */

.billing-address {
  margin-bottom: 35px;
  margin-top: -12px;

  @media@md {
    padding-right: 31px;
    margin-bottom: 26px;
  }

  h2 {
    letter-spacing: 0;
  }
}

.shipping-info {
  margin-bottom: 35px;

  @media@md {
    margin-bottom: 30px;
  }

  h2 {
    letter-spacing: 0;
  }
}
