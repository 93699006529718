/* .product-index */

.product-index {
  margin-bottom: 56px;

  &__more {
    text-align: center;
    padding-top: 9px;
  }

  &__item {
    display: flex;
  }
}