/* .icon */
.icon {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-image: url("../img/@{spritesheet-image}") !important;
  }

  //     &-example {
  //         &:before {
  //             .sprite(@icon-example);
  //         }
  //     }

  &-cart {
    &:before {
      .sprite(@icon-cart);
    }
  }

  &-sandwich {
    &:before {
      .sprite(@icon-sandwich);
    }
  }

  .header__toggle-menu.opened &-sandwich,
  &-close-menu {
    &:before {
      .sprite(@icon-close-menu);
    }
  }

  &-reviews {
    &:before {
      .sprite(@icon-reviews);
    }
  }

  &-accordion {
    &:before {
      .sprite(@icon-accordion);
    }
  }

  &-flag-es {
    &:before {
      .sprite(@icon-flag-es);
    }
  }

  &-flag-us {
    &:before {
      .sprite(@icon-flag-us);
    }
  }

  &-prop-free-ship {
    &:before {
      .sprite(@icon-prop-free-ship);
    }
  }

  &-prod-hot {
    &:before {
      .sprite(@icon-prod-hot);
    }
  }

  &-prod-bonus {
    &:before {
      .sprite(@icon-prod-bonus);
    }
  }

  &-qty-minus {
    &:before {
      .sprite(@icon-qty-minus);
    }
  }

  &-qty-plus {
    &:before {
      .sprite(@icon-qty-plus);
    }
  }
}
